import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faLock, faCamera, faMicrophone } from '@fortawesome/free-solid-svg-icons'
import { navigate } from "gatsby";
import Jitsi from 'react-jitsi';
import InputMask from 'react-input-mask';

import Layout from "../components/layout";
import { saveRecentMeeting } from "../helpers/local";

const JoinMeeting = () => {
    const [mask, setMask] = useState("999-999-99999");
    const [roomName, setRoomName] = useState('');
    const [password, setPassword] = useState('');
    const [videoOn, setVideoOn] = useState(true);
    const [microphoneOn, setMicrophoneOn] = useState(true);
    const [onCall, setOnCall] = useState(false);
    const [switchTitle, setSwitchTitle] = useState('Join with meeting name');

    const switchInput = () => {
        setRoomName('');
        if (mask == null) {
            setMask("999-999-99999");
        } else {
            setMask(null);
        }

        if (switchTitle === 'Join with meeting name') {
            setSwitchTitle('Join with meeting id');
        } else {
            setSwitchTitle('Join with meeting name');
        }
    }

    const jitsiAPILoad = (JitsiMeetAPI) => {
        if (!videoOn) {
            JitsiMeetAPI.executeCommand('toggleVideo');
        }

        if (!microphoneOn) {
            JitsiMeetAPI.executeCommand('toggleAudio');
        }
        
        JitsiMeetAPI.addEventListener("videoConferenceLeft", () => {
            initializeInputs();
        });
        JitsiMeetAPI.addEventListener("suspendDetected", () => {
            initializeInputs();
        });
        saveRecentMeeting({
            meetingTitle: mask == null ? roomName : roomName.replace(/-/g, '').replace(/_/g, ''),
            meetingPassword: password,
            meetingId: mask == null ? roomName : roomName.replace(/-/g, '').replace(/_/g, ''),
            hostPreferences: {
                audio: microphoneOn,
                video: videoOn,
            },
            joinedTime: Math.floor(new Date().getTime() / 1000)
        });
    }

    const initializeInputs = () => {
        setOnCall(false);
        setVideoOn(true);
        setMicrophoneOn(true);
    }
   
    return (
        <div>
            { 
                onCall && roomName && 
                <Jitsi
                    containerStyle={{ width: window.innerWidth, height: window.innerHeight }}
                    roomName={mask == null ? roomName : roomName.replace(/-/g, '').replace(/_/g, '')}
                    password={password}
                    onAPILoad={JitsiMeetAPI => jitsiAPILoad(JitsiMeetAPI)}
                    domain="app.meetnow.in" />
            }
            {
                !onCall && 
                <Layout>
                    <div className="center_colm">
                        <div className="center_colm_title">
                            <div className="btn_back" onClick={() => navigate("/dashboard")}>
                                <FontAwesomeIcon className="fa_icon" icon={faChevronLeft} />
                            </div>
                            Join a meeting
                        </div>
                        <div className="ct_item">
                            <label>Enter meeting ID*</label>
                            <InputMask mask={mask} autoFocus={true} value={roomName} onChange={(event) => setRoomName(event.target.value)} />
                            <div className="btn_switch_input mt-3" onClick={() => switchInput()}>{switchTitle}</div>
                        </div>
                        <div className="ct_item">
                            <label>Enter meeting password</label>
                            <input type="text" placeholder="Enter a meeting password" value={password} onChange={(event) => setPassword(event.target.value)} />
                        </div>
                        <div className="options"><span>Options</span>
                            <ul className="list-unstyled">
                                <li className="m_psw">
                                    <div>
                                        <FontAwesomeIcon className="fa_icon" icon={faCamera} />
                                        <a href="#">Video on</a>
                                    </div>
                                    <label className="switch" htmlFor="video_on">
                                        <input type="checkbox" id="video_on" defaultChecked value={videoOn} onChange={(event) => setVideoOn(!videoOn)} />
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                                <li className="m_psw">
                                    <div>
                                        <FontAwesomeIcon className="fa_icon" icon={faMicrophone} />
                                        <a href="#">Microphone on</a>
                                    </div>
                                    <label className="switch" htmlFor="microphone_on">
                                        <input type="checkbox" id="microphone_on" defaultChecked value={microphoneOn} onChange={(event) => setMicrophoneOn(!microphoneOn)} />
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                            </ul>
                            <button className="start_m_btn btn start-mt" onClick={() => setOnCall(true)} disabled={
                                mask == null ? !roomName : !roomName.replace(/-/g, '').replace(/_/g, '').length
                            }>
                                Join a meeting
                            </button>
                        </div>
                    </div>
                </Layout>
            }
        </div>
    )
}
export default JoinMeeting