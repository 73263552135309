export const saveRecentMeeting = (recentMeeting) => {
    let recentMeetings = getRecentMeetings();
    recentMeetings.push(recentMeeting);
    localStorage.setItem("recent_meetings", JSON.stringify(recentMeetings));
}

export const getRecentMeetings = () => {
    let recentMeetings = localStorage.getItem("recent_meetings");
    if (recentMeetings) {
        recentMeetings = JSON.parse(recentMeetings);
        return recentMeetings;
    }
    return [];
}

export const removeRecentMeeting = (index) => {
    let recentMeetings = getRecentMeetings();
    recentMeetings.sort((r1, r2) => {
        return r2.joinedTime - r1.joinedTime
    });
    recentMeetings.splice(index, 1);
    localStorage.setItem("recent_meetings", JSON.stringify(recentMeetings));
}

export const getItem = (itemName) => {
    const item = localStorage.getItem(itemName);

    if (item) {
        return item;
    }
    return "";
}

export const setItem = (itemName, itemContent) => {
    localStorage.setItem(itemName, itemContent);
}